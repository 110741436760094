<script>
  import { useTasksStore } from '@/stores/tasks_store'
  import Task from "@/components/tasks/task.vue";
  export default {
    components: {Task},
    data: () => {
      return {
        store: useTasksStore()
      }
    },
    created() {
      // this.store.initTasks()
    },
    mounted() {
      this.$cable.subscribe({
        channel: 'TasksChannel',
        room: 'tasks_list'
      });
    },
    channels: {
     TasksChannel: {
       received(data) {
         this.updateTasks(data)
       }
     }
    },
    methods: {
      runMessageSender() {
        this.store.runMessageSender()
      },
      runProductsParser() {
        this.store.runProductsParser()
      },
      runUsersParser() {
        this.store.runUsersParser()
      },
      updateTasks(data) {
        if (this.store.currentTaskIds.includes(data.task.job_id)) {
          const index = this.store.tasks.findIndex((t) => t.job_id === data.task.job_id)
          this.store.tasks[index] =  data.task
        } else {
          this.store.tasks.unshift(data.task)
        }
      }
    }
  }


</script>

<template>
  <div class="tasks-list">
    <h1>Running Tasks</h1>

    <div class="parser-buttons">
      <button @click="runProductsParser">run products parser</button>
      <button @click="runUsersParser">run users parser</button>
      <button @click="runMessageSender">run message sender</button>
    </div>

    <task v-for="task in store.tasks" :task="task" />

  </div>
</template>

<style>
  .parser-buttons {
    display: flex;
    gap: 5px;
    margin-bottom: 20px;
  }
</style>