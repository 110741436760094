import { defineStore } from 'pinia'
import api from '@/plugins/api'

export const useTasksStore = defineStore('tasks', {
  state: () => {
    return {
      loaded: false,
      saving: false,
      tasks: []
    }
  },
  getters: {
    currentTaskIds(state) {
      return state.tasks.map( task => task.job_id)
    }
  },
  actions: {
    initTasks() {
      // this.loadTasks()
    },
    async loadTasks(options={}) {
      this.tasks = await api.get('/api/v1/tasks').tasks
    },
    runProductsParser() {
      api.post('/api/v1/tasks/run_products_parser')
    },
    runUsersParser() {
      api.post('/api/v1/tasks/run_users_parser')
    },
    runMessageSender() {
      api.post('/api/v1/tasks/run_message_sender')
    },
    stopTask(jid) {
      api.delete(`/api/v1/tasks/${jid}/cancel_task`)
    }
  }
})