<template>
  <div class="task" :data-id="task.job_id">
    <h4>{{humanKind}}</h4>
    <p v-if="task.kind === 'product_parser'">Search links: <b>{{task.link_index+1}}/{{task.total_links}}</b></p>
    <p>Current link: <b>{{task.current_link}}</b></p>
    <p>Pages: <b>{{task.current_page}}/{{task.total_pages}}</b></p>
    <div v-if="canceled">
      Task stopped
    </div>
    <div v-else>
      <div class="mb-3">
        <div class="progress" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
          <div class="progress-bar bg-success" :style="`width: ${productParserPercent}%`">{{productParserPercent}}%</div>
        </div>
      </div>

      <button @click="cancelTask" class="btn btn-danger">STOP</button>
    </div>

  </div>
</template>

<script>
  import { useTasksStore } from '@/stores/tasks_store'
  export default {
    props: ['task'],
    data: () => {
      return {
        store: useTasksStore(),
        canceled:false
      }
    },
    methods: {
      cancelTask() {
        if (confirm('Stop task?')) {
          this.canceled = true
          this.store.stopTask(this.task.job_id)
        }
      }
    },
    computed: {
      humanKind() {
        if (this.task.kind === 'product_parser') {
          return 'Search Links Parser'
        } else if (this.task.kind === 'author_parser') {
          return 'Users Parser'
        } else if (this.task.kind === 'message_sender') {
          return 'Message Sender'
        } else {
          return ''
        }
      },
      productParserPercent() {
        return (this.task.current_page*100)/this.task.total_pages
      }
    }
  }
</script>

<style>
  .task {
    background-color: #ffffff;
    color: #1c252e;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 30px;
  }
</style>
