class Api {

  #onResponse(res) {
    return res.ok ? res.json() : res.json().then(err => Promise.reject(err))
  }

  getHeaders() {
    return {'content-type': 'application/json','X-Requested-With' : 'XMLHttpRequest'}
  }

  postHeaders() {
    return {...this.getHeaders(),...{'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')}}
  }

  get(route) {
    return fetch(route, {headers: this.getHeaders()}).then(this.#onResponse)
  }

  post(route, payload) {
    return fetch(route, {headers: this.postHeaders(), method: 'POST', body: payload}).then(this.#onResponse)
  }

  put(route, payload) {
    return fetch(route, {headers: this.postHeaders(), method: 'PUT', body: payload}).then(this.#onResponse)
  }

  delete(route) {
    return fetch(route, {headers: this.postHeaders(), method: 'DELETE'}).then(this.#onResponse)
  }

}

const api = new Api()
export default api;