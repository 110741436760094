// import Rails from '@rails/ujs';
// Rails.start();

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import ActionCableVue from "actioncable-vue";
import Tasks from '@/components/tasks/tasks.vue'

const actionCableVueOptions = {
    debug: process.env.NODE_ENV === 'development',
    debugLevel: "error",
    connectionUrl: process.env.NODE_ENV === 'development' ? 'ws://localhost:5100/cable' : 'wss://realty24.de/cable',
    connectImmediately: true,
};


document.addEventListener('DOMContentLoaded', () => {
    //console.log(process.env.NODE_ENV);
    const vue_tasks = document.getElementById('v-tasks');
    if (vue_tasks) {
        const app = createApp(Tasks,{});
        const pinia = createPinia();
        app.use(pinia);
        app.use(ActionCableVue, actionCableVueOptions);
        app.mount(vue_tasks);
    }
})

import Rails from "@rails/ujs"
if (typeof Rails === 'undefined') {
    Rails.start()
}
